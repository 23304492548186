export default function IndexPage() {
  return (
    <div className='flex items-center justify-center w-screen h-screen bg-black'>
      <img
        src='/full-logo-white.png'
        alt='Tablelist Logo'
        className='object-contain object-center'
        width={514 / 2}
      />
    </div>
  )
}
